export const LianaMailer = {
  ajax: function (a, b, c, d, e) {
    var f;
    if ('undefined' != typeof XMLHttpRequest) f = new XMLHttpRequest();
    else
      for (
        var g = [
            'MSXML2.XmlHttp.5.0',
            'MSXML2.XmlHttp.4.0',
            'MSXML2.XmlHttp.3.0',
            'MSXML2.XmlHttp.2.0',
            'Microsoft.XmlHttp'
          ],
          h = 0,
          i = g.length;
        h < i;
        h++
      )
        try {
          // eslint-disable-next-line no-undef
          f = new ActiveXObject(g[h]);
          break;
        } catch (_) {
          /* Do nothing! */
        }
    (f.onreadystatechange = function () {
      if (!(f.readyState < 4))
        if (4 === f.readyState)
          if (200 === f.status) d(f);
          else e();
    }),
      f.open(b, a, !0),
      f.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded'),
      f.send(c);
  },
  serialize: function (a) {
    for (var b = [], c = 0; c < a.elements.length; c++) {
      var d = a.elements[c];
      if (
        d.name &&
        !d.disabled &&
        'file' !== d.type &&
        'reset' !== d.type &&
        'submit' !== d.type &&
        'button' !== d.type
      ) {
        if (('checkbox' !== d.type && 'radio' !== d.type) || d.checked)
          b.push(encodeURIComponent(d.name) + '=' + encodeURIComponent(d.value));
      } else;
    }
    return b.join('&');
  },
  guid: function () {
    return (
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
  },
  setAttributes: function (a, b) {
    for (var c in b) a.setAttribute(c, b[c]);
  },
  showError: function (a) {
    var b = this.form.querySelector('[data-lianamailer="formfield-error"]');
    if (!a) a = 'Tapahtui virhe - Toimintoa ei voitu suorittaa loppuun.';
    if (b) (b.textContent = a), b.focus();
    else
      (b = document.createElement('div')),
        (b.textContent = a),
        this.setAttributes(b, {
          class: 'lianamailer-formfield lianamailer-formfield-error',
          'data-lianamailer': 'formfield-error',
          tabindex: '-1'
        }),
        (b.style.outline = 'none'),
        (b.style.display = 'block'),
        this.form.insertBefore(b, this.form.childNodes[0]),
        b.focus();
  },
  showSuccess: function () {
    for (
      var a = this.form.querySelectorAll('[data-lianamailer^=formfield]'), b = 0;
      b < a.length;
      b++
    )
      a[b].style.display = 'none';
    var c = this.form.querySelector('[data-lianamailer="success"]');
    c.style.display = 'block';
  },
  submit: function () {
    var a = this;
    a.form = document.querySelector('[data-lianamailer="form"]');
    var c = document.querySelector('[data-lianamailer="submit"]');
    // @ts-ignore
    (c.disabled = !0),
      a.ajax(
        'https://uutiskirje.kauppakeskusmylly.fi/json?_=' + a.guid(),
        'get',
        '',
        function (b) {
          var d = JSON.parse(b.responseText);
          (d = a.serialize(a.form) + '&cfcfcfcfcf=' + d.csrf_token),
           a.ajax(
              'https://uutiskirje.kauppakeskusmylly.fi/account?ajax',
              'post',
              d,
              function (b) {
                var d = JSON.parse(b.responseText);
                if (d.success) a.showSuccess();
                else a.showError(d.error_msg);
                c.disabled = !1;
              },
              function () {
                a.showError(), (c.disabled = !1);
              }
            ); 
        },
        function () {
          a.showError(), (c.disabled = !1);
        }
      );
  }
};
