<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import { LianaMailer } from "./lianamailer";
  import BodyContent from "../BodyContent.svelte";
  import { getGrecaptcha } from "./grecaptcha-util";
  import { getStoresContext } from "~/stores";
  import { valueGetters } from "~/components/fragment-sets/basic_component/util";

  export let fragment: Fragment;
  export let request_store_key;
  const stores_context = getStoresContext(request_store_key);
  const { locale, T } = stores_context;
  const { getText, getHtml } = valueGetters(stores_context);
  const { getFragmentConfig } = stores_context.fieldUtils();

  let colorBckground:any;

  $: title = $locale ? getText("title", fragment) : "";
  $: intro = $locale ? getText("intro", fragment) : "";
  $: submit = $locale ? getText("submit", fragment) : "";
  $: consent = $locale ? getHtml("consent", fragment) : "";
  $: {
    colorBckground = getFragmentConfig("coloredBackground", fragment);
  }

  const recaptcha_key = '6LfIv74ZAAAAAO_qT3Dywy2S5UlMb1Bt-mprudQj';
  let email_element: HTMLInputElement;
  let consent_element: HTMLInputElement;
  function validate() {
    if (!email_element.value || !consent_element.checked) {
      return;
    }
    getGrecaptcha().ready(() => {
      getGrecaptcha().execute(widget_id);
    });
  }

  $: widget_id = null;

  onMount(() => {
    getGrecaptcha().ready(() => {
      widget_id = getGrecaptcha().render("newsletter-recaptcha", {
        sitekey: recaptcha_key,
        callback: doSubmit,
        size: "invisible",
      });
    });
  });

  const doSubmit = function () {
    const lianamailer = Object.create(LianaMailer);
    lianamailer.submit();
  };
  $: t = $T;
</script>

<svelte:head>
  <script src="https://www.google.com/recaptcha/api.js" async defer></script>
</svelte:head>
{#if title || intro}
  <div class="newsletter-wrapper" class:colored={colorBckground}>
    <picture>
      <source
        media="(min-width:576px)"
        srcset="/theme/mylly2023/assets/newsletter-bg-desktop.png" />
      <img
        src="/theme/mylly2023/assets/newsletter-bg-mobile.png"
        alt="newsletter background decoration"
        width="375"
        height="753" />
    </picture>
    <div class="content-wrapper cont-ov-h">
      <BodyContent>
        <div class="main-text">{title}</div>
        <div class="sub-text">{intro}</div>
        <form
          id="newsletter-form"
          data-lianamailer="form"
          method="post"
          on:submit|preventDefault={validate}>
          <div
            class="lianamailer-formfield lianamailer-formfield-properties"
            data-lianamailer="formfield">
            <input
              type="email"
              name="email"
              class="email-field"
              id="email"
              bind:this={email_element}
              required
              placeholder={t("email-address")} />
          </div>

          <div
            class="lianamailer-formfield lianamailer-formfield-consents check-privacy-policy"
            data-lianamailer="formfield">
            <label class="form-check-label" for="privacy-policy">
              <input
                class="form-check-input"
                id="consent"
                type="checkbox"
                required
                name="consent[]"
                bind:this={consent_element}
                value="458-1-fi" />
              {@html consent}
            </label>
          </div>

          <input type="hidden" name="join" value="64289" />

          <div aria-hidden="true" style="position: absolute; left: -7777px">
            <input type="text" name="lm-gtfo" tabindex="-1" value="" />
          </div>

          <div
            class="lianamailer-success"
            data-lianamailer="success"
            style="display: none">
            Kiitos tilauksestasi
          </div>

          <div
            class="lianamailer-formfield lianamailer-formfield-submit"
            data-lianamailer="formfield-submit">
            <div id="newsletter-recaptcha" data-sitekey={recaptcha_key} />

            <input
              class="action-button"
              id="newsletter-submit"
              type="submit"
              data-lianamailer="submit"
              value={submit} />
          </div>
        </form>
      </BodyContent>
    </div>
  </div>
{/if}

<style lang="scss">
  .cont-ov-h{
    overflow: hidden;
  }
  .colored {
    background: #fff5f1;
  }
  .newsletter-wrapper {
    position: relative;

    img {
      width: 100%;
      min-height: 700px;

      @media screen and (min-width: 576px) {
        max-height: 880px;
        object-fit: cover;
        object-position: top;
      }
    }
    .content-wrapper {
      position: absolute;
      top: 30%;
      width: 100%;
      text-align: center;
      margin: auto;
      @media screen and (min-width: 576px) {
        top: 50%;
        transform: translateY(-15%);
      }
    }
    .main-text {
      max-width: 90%;
      margin: 0 auto 40px auto;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      @media screen and (min-width: 576px) {
        margin: 0 auto 15px auto;
      }
    }
    .sub-text {
      width: 90%;
      margin: 0 auto 40px auto;
      font-size: 16px;
      line-height: 22px;
      @media screen and (min-width: 576px) {
        margin: 0 auto 30px auto;
      }

      @media screen and (min-width: 992px) {
        width: 38%;
      }
    }

    form {
      margin: 0 auto;
      max-width: 430px;
    }

    .email-field {
      width: 300px;
      height: 45px;
      margin: 0 auto 35px auto;
      background: #fff;
      border: none;
      border-radius: 23px;
      color: #4e545b;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
      @media screen and (min-width: 576px) {
        margin: 0 auto 25px auto;
      }
    }
    .action-button {
      width: 230px;
      height: 45px;
      margin: auto;
      background: #000;
      border: none;
      border-radius: 23px;
      color: #fff;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
    }

    .check-privacy-policy {
      width: fit-content;
      margin: auto;
      margin-bottom: 30px;
      @media screen and (min-width: 576px) {
        margin-bottom: 20px;
      }
      .form-check-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        display: inline-block;
      }
    }

    .check-privacy-policy :global(a) {
      font-weight: bold;
      color: #000;
    }
  }
</style>
